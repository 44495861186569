import React from "react"
import ButtonComponent from "../ButtonComponent/ButtonComponent"

class DevProcess extends React.Component {

  render() {
    return (
      <section className="process-of fintech pt-100 mb-0">
         <div className="gray-bg">
            <div className="container">     
              <h2 className="heading-h2 text-center"><span className="bluecolor">Process Of </span> Our Fintech Software Development
              </h2>
              <p className="text-center">
              Our developer team offers an innovative development approach to ease your Fintech business needs and requirements.
              </p>
              <div className="d-lg-flex add-one">
                <div className="square" >
                  <h3>Ideation</h3>
                  <p className="pharagraph">
                  In the initial stage, we conduct our clients to collect the required information and needs to kickstart the fintech business. Our team understands the competitors, and technical specifications to be implemented into the software. Our developers help to frame the perfect roadmap before involving in the development phase.
                  </p>
                </div>
                <div className="square">
                  <h3>Market Analysis</h3>
                  <p className="pharagraph">
                  Our expert team then carefully analyzes the expectations, needs, and requirements of our clients to come up with a perfect solution. Analyzing the current market trends helps you to know the loopholes, risks, and challenges of building a potential fintech business. We also help you to allocate the resources accordingly.
                  </p>
                </div>
                <div className="square" >
                  <h3>Designing Phase</h3>
                  <p className="pharagraph">
                  After the specified resources are gathered, we can then design any fintech project. Visual effects, technical features, security protocols, and themes are curated into the project to seek users' attention. Even you can create a prototype for beta test for more efficient results and outcomes.
                  </p>
                </div>
              </div>
              <div className="d-lg-flex add-three">
                <div className="square" >
                  <h3>Development</h3>
                  <p className="pharagraph">
                  Our team is involved in the agile development of the project with the help of advanced-level programming languages, codings, and technical tools. We ensure to offer robust, transparent, and scalable fintech solutions for your project. Our developer team splits the development procedure into iterations to get better results.
                  </p>
                </div>
                <div className="square">
                  <h3>Quality Assurance</h3>
                  <p className="pharagraph">
                  Repeated testing and quality assurance methodologies are followed to ensure the quality of the project. Each iteration phase is associated with rapid testing methods to rectify technical errors and bugs. Our testing team takes complete responsibility for offering you the best outcomes.
                  </p>
                </div>
                <div className="square" >
                  <h3>Prolonged Support</h3>
                  <p className="pharagraph">
                  Our team helps you to deploy the project with existing systems and third parties. Although the development process is over, we provide support and maintenance for your project. Our developer and expert team will be available at any time to rectify your project’s technical mistakes.
                  </p>
                </div>
              </div>
              <div className="mt-3 text-center">
                  <ButtonComponent />
              </div>
            </div>
          </div>
      </section>

    )
  }
}

export default DevProcess
