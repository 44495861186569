import React from 'react'



class Whatis extends React.Component {

  render() {

    return (
      <section className="whatis pt-0">                                                                                             
        <div className='gray-bg'>
        <div className="container">
                <h2 className="heading-h2 text-center"><span className='bluecolor'>Coinsclone </span>- A Premier Fintech Software Development Company</h2>
            <div className="text-center">
              <p className="pharagraph text-center">We, a superior fintech software development company help aspiring entrepreneurs build fintech-related applications for diverse use cases. Our fintech software development is reliable and is deemed to be the best in the business as we ensure data security and regulatory compliance. By hiring our fintech developers, you can build all possible innovative software at a reasonable cost and an affordable price.
              </p>
              <p className="pharagraph text-center mb-0">Also, we initiate tailored fintech-related ideas for entering the market and making revolutions. The fintech apps have creative UI/UX features along with excellent revenue models which allows startups to make huge strides. As a result, entrepreneurs can have deeper and more user-centric business solutions. Overall, fintech software development is the process by which startups develop highly functional, easier, and multiple-revenue earning software with the help of a professional fintech software development company. 
              </p>
            </div>
        </div>
        </div>
      </section>
    )
  }
}

export default Whatis