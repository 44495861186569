
import React from 'react'



class WhatMake extends React.Component {


  render() {

    return (
      <section className="what-make tokenize fintech pt-100 mb-0">   
          <div className="container">
            <h2 className="heading-h2 text-center"><span className='bluecolor'>Excellent Features And Functionalities</span> We Provide In Our Fintech Software Development Services</h2>
            <p className='text-center'>Revalotinize the digitalized financial world with our exemplary features and plugins. Our technical team conceptualizes your ideas with ease.</p>
              <div className='d-flex flex-wrap justify-content-center ind-std'>
                  <p>Multiple Account Management</p>
                  <p>Payment Gateway Integration</p>
                  <p>Data Analytics</p>
                  <p>Calculators for Loan and Tax</p>
                  <p>Customer Data Management</p>
                  <span></span>
                  <p>Multi-Modal Transactions</p>
                  <p>Card Management System</p>
                  <p>Data Visualization</p>
                  <p>Chatbots And Online Assistants</p>
              </div>
          </div>
      </section>
    )
  }
}

export default WhatMake