import React from 'react';
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import BannerCrypto from '../components/Fintech/Banner';
import Whatis from '../components/Fintech/Whatis';
import DevService from '../components/Fintech/DevServices';
import WhyChoose from '../components/Fintech/WhyChoose';
import FaqSection from '../components/Fintech/FaqSection';
import UserFriendly from '../components/Cryptoexchange/UserFriendly';
import Benificial from '../components/Fintech/Benificial';
import TechStack from '../components/Fintech/TechStack';
import DevProcess from '../components/Fintech/DevProcess';
import FeatureBenefits from '../components/Fintech/FeatureBenefits';
import VariousBlock from '../components/Fintech/VariousBlock';
import WhatMake from '../components/Fintech/WhatMake';


const Fintech = () => (
  <section className="cryptoex">
    <Layout>
      <Helmet>
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.coinsclone.com/fintech-software-development-company/" />
        <title>Fintech Software Development Company - Coinsclone</title>
        <meta name="description" content="Coinsclone is a world-class Fintech software development company design, builds, and deploys Fintech business solutions for newbie startups and entrepreneurs." />
        <meta name="keywords" content="Fintech Software Development Company, Fintech Software Development Services" />
        <meta property="og:locale" content="en_US" /> 
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Fintech Software Development Company - Coinsclone" />
        <meta property="og:description" content="Coinsclone is a world-class Fintech software development company design, builds, and deploys Fintech business solutions for newbie startups and entrepreneurs." />
        <meta property="og:url" content="https://www.coinsclone.com/fintech-software-development-company/" />
        <meta property="og:image" content="https://coinsclone.mo.cloudinary.net/images/fintech/fintech-software-development-company.webp" />
        <meta property="og:site_name" content="Coinsclone" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Coinsclone" />
        <meta name="twitter:creator" content="@Coinsclone" />
        <meta name="twitter:description" content="Coinsclone is a world-class Fintech software development company design, builds, and deploys Fintech business solutions for newbie startups and entrepreneurs." />
        <meta name="twitter:title" content="Fintech Software Development Company - Coinsclone" />
        <meta name="twitter:image" content="https://coinsclone.mo.cloudinary.net/images/fintech/fintech-software-development-company.webp" />
      </Helmet>
      
      <BannerCrypto /> 
      <div className="breadcrumb wallet-banner mb-0">
        <div className="container">
          <span><a href="https://www.coinsclone.com/">Home</a> | Fintech Software Development Company</span>
        </div>
      </div>
      <Whatis />
      <Benificial />
      <DevService />
      <WhatMake />
      <VariousBlock />
      <FeatureBenefits />
      <DevProcess />
      <WhyChoose />
      <TechStack />
      <FaqSection />
      <UserFriendly />
    </Layout>
  </section>
)

export default Fintech
