import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'


class WhyChoose extends React.Component {

  render() {

    return (
      <section className="whatis pt-100 mb-0 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className="bluecolor">Why Coinsclone </span>Is The Best Fintech Software Development Company?</h2>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-6 col-md-12 text-center">
              <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/fintech/why-coinsclone-fintech.webp"
                alt="best Fintech Software development company"
                placeholder='none'
                className='mt-3 mb-3'
                width={530}
              />
            </div>
            <div className="col-lg-6 col-md-12">
              <p className="pharagraph">Coinsclone is an advanced-level Fintech software development company with a pool of skilled developers, technicians, and experts. As a renowned Fintech software provider, we have nearly completed 250+ projects that made startups taste success. Our team of developers leverage your business to the next level. We offer various Fintech software solutions that will be your long-lasting investment.
              </p>
              <p className="pharagraph">Empower your business ideas into reality with our technical development services. We execute We excel in integrating advanced-level features and plugins into your project to turn traditional financial systems completely digitalized. Our developer team crafts every pixel of your project with excellence. We assure to transform challenges & risks into triumphs. Collaborate with us to succeed in the crypto universe.
              </p>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default WhyChoose