import React from 'react'



class VariousBlock extends React.Component {


  render() {

    return (
      <section className="industries specific pt-100 mb-0">
        <div className='gray-bg'>
          <div className="container">
            <div className="row">
                <h2 className="heading-h2 text-center"><span className="bluecolor">Industry-Specific Use Cases </span> Of Our Fintech Software
                </h2>
                <p className="pharagraph head text-center">Discover the potential use cases of Fintech software solutions and start succeeding in the digital world.
                </p>
            </div>
            <div className='row popular we-offer'>
                <div className='col-md-12 col-lg-3'>
                    <h3>Digital Payments</h3>
                    <p className='pharagraph'>We utilize Fintech software development services help to create apps that eventually bring security and enhance payment services.</p>
                </div>
                <div className='col-md-12 col-lg-3'>
                    <h3>Banking Applications</h3>
                    <p className='pharagraph'>Our team will stand behind you to infuse secured Fintech software solutions to leverage banking services for customers.</p>
                </div>
                <div className='col-md-12 col-lg-3'>
                    <h3>Wealth Management</h3>
                    <p className='pharagraph'>We offer services that ensure individuals manage their investment portfolios, retirement planning, and long-term financial goals.</p>
                </div>
                <div className='col-md-12 col-lg-3'>
                    <h3>Lending Platforms</h3>
                    <p className='pharagraph'>Implementation of Fintech software solutions in lending platforms will elevate seamless transactions and accuracy.</p>
                </div>
            </div>
          </div>
        </div>
    </section>
    )
  }
}

export default VariousBlock